import { Box, Flex, Heading1, Heading2, Heading3, Heading4 } from "@nulogy/components";
import { COPY_WIDTH } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Box,
  Flex,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  COPY_WIDTH,
  React
};