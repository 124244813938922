import { Box, Text } from "@nulogy/components";
import { CODE_WIDTH } from "../../../../src/components/CONSTANTS";
import figmaComponents from "../../../../src/pages/guides/images/designers-guide/figma-components.gif";
import figmaStyles from "../../../../src/pages/guides/images/designers-guide/figma-styles.gif";
import figmaText from "../../../../src/pages/guides/images/designers-guide/figma-text.gif";
import figmaGrid from "../../../../src/pages/guides/images/designers-guide/figma-grid.gif";
import figmaFrame from "../../../../src/pages/guides/images/designers-guide/figma-frame.gif";
import figmaVersion from "../../../../src/pages/guides/images/designers-guide/figma-version.gif";
import figmaSaveVersion from "../../../../src/pages/guides/images/designers-guide/figma-save-version.gif";
import { FIGMA_LINK } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Box,
  Text,
  CODE_WIDTH,
  figmaComponents,
  figmaStyles,
  figmaText,
  figmaGrid,
  figmaFrame,
  figmaVersion,
  figmaSaveVersion,
  FIGMA_LINK,
  React
};