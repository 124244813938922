import { Box, Flex, Text, Heading3, Button, PrimaryButton, DangerButton, QuietButton } from "@nulogy/components";
import { config } from "../../../../src/playgrounds/button";
import { COPY_WIDTH } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Box,
  Flex,
  Text,
  Heading3,
  Button,
  PrimaryButton,
  DangerButton,
  QuietButton,
  config,
  COPY_WIDTH,
  React
};