/* This Array configures the the sections and name, href, and title for each page on the website.
For the page title that appears in the browser tab, the name will be used unless a title is specified. */

export const navigationLinks = [
  {
    name: "Start here",
    links: [
      {
        name: "Designing with NDS",
        href: "/guides/designers",
      },
      {
        name: "Developer Workflow",
        href: "/guides/developer-workflow",
      },
      {
        name: "Theme",
        href: "/theme",
      },
    ],
  },
  {
    name: "Design Language",
    links: [
      {
        name: "Design Principles",
        href: "/design-language/principles",
      },
      {
        name: "Colour",
        href: "/design-language/colour",
      },
      {
        name: "Typography",
        href: "/design-language/typography",
      },
      {
        name: "Spacing",
        href: "/design-language/spacing",
      },
      {
        name: "Shadows",
        href: "/design-language/shadows",
      },
    ],
  },
  {
    name: "Components",
    links: [
      {
        name: "Alert",
        href: "/components/alert",
      },
      {
        name: "Box",
        href: "/components/box",
      },
      {
        name: "Breadcrumbs",
        href: "/components/breadcrumbs",
      },
      {
        name: "DateRange",
        href: "/components/date-range",
      },
      {
        name: "Input",
        href: "/components/input",
      },
      {
        name: "Pagination",
        href: "/components/pagination",
      },
      /* Add routes here */
      {
        name: "ButtonGroup",
        href: "/components/button-group",
      },
      {
        name: "AsyncSelect",
        href: "/components/async-select",
      },
      {
        name: "Overlay",
        href: "/components/overlay",
      },
      {
        name: "FormSection",
        href: "/components/form-section",
      },
      {
        name: "Form",
        href: "/components/form",
      },
      {
        name: "BrandedNavBar",
        href: "/components/branded-nav-bar",
      },
      {
        name: "Divider",
        href: "/components/divider",
      },
      {
        name: "Flex",
        href: "/components/flex",
      },
      {
        name: "Table",
        href: "/components/table",
      },
      {
        name: "Tooltip",
        href: "/components/tooltip",
      },
      {
        name: "Toggle",
        href: "/components/toggle",
      },
      {
        name: "List",
        href: "/components/list",
      },
      {
        name: "Toast",
        href: "/components/toast",
      },
      {
        name: "TimeRange",
        href: "/components/time-range",
      },
      {
        name: "TimePicker",
        href: "/components/time-picker",
      },
      {
        name: "Textarea",
        href: "/components/textarea",
      },
      {
        name: "Tabs",
        href: "/components/tabs",
      },
      {
        name: "StatusIndicator",
        href: "/components/status-indicator",
      },
      {
        name: "Sidebar",
        href: "/components/sidebar",
      },
      {
        name: "Select",
        href: "/components/select",
      },
      {
        name: "Switcher",
        href: "/components/switcher",
      },
      {
        name: "RadioGroup",
        href: "/components/radio-group",
      },
      {
        name: "Radio",
        href: "/components/radio",
      },
      {
        name: "Page",
        href: "/components/page",
      },
      {
        name: "Modal",
        href: "/components/modal",
      },
      {
        name: "LoadingAnimation",
        href: "/components/loading-animation",
      },
      {
        name: "Icon",
        href: "/components/icon",
      },
      {
        name: "TruncatedText",
        href: "/components/truncated-text",
      },
      {
        name: "IconicButton",
        href: "/components/iconic-button",
      },
      {
        name: "Buttons",
        href: "/components/buttons",
      },
      {
        name: "DropdownMenu",
        href: "/components/dropdown-menu",
      },
      {
        name: "DatePicker",
        href: "/components/date-picker",
      },
      {
        name: "CheckboxGroup",
        href: "/components/checkbox-group",
      },
      {
        name: "Checkbox",
        href: "/components/checkbox",
      },
      {
        name: "Link",
        href: "/components/link",
      },
      {
        name: "ApplicationFrame",
        href: "/components/application-frame",
      },
      {
        name: "Branding",
        href: "/components/branding",
      },
      {
        name: "Text",
        href: "/components/text",
      },
      {
        name: "Headings",
        href: "/components/headings",
      },
    ],
  },
  {
    name: "Patterns",
    links: [
      {
        name: "Loading content",
        href: "/patterns/loading",
      },
      {
        name: "Data visualization",
        href: "/patterns/data-visualization",
      },
    ],
  },
  {
    name: "Resources",
    links: [
      {
        name: "Localization",
        href: "/guides/localization",
      },
      {
        name: "Ops Core",
        href: "/guides/ops-core",
        title: "Using NDS in Operational Solution Core",
      },
      {
        name: "Releasing",
        href: "/guides/packages",
        title: "How the Nulogy Design Ops team releases packages",
      },
      {
        name: "Style Props",
        href: "/guides/style-props",
      },
      {
        name: "Testing",
        href: "/guides/testing",
      },
      {
        name: "Versioning",
        href: "/guides/versioning",
      },
      {
        name: "*** We're hiring ***",
        href: "https://nulogy.com/careers/",
      },
    ],
  },
];
