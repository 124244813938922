import { Box, List, ListItem, Link, Heading1, Heading2, Text, Divider, Flex } from "@nulogy/components";
import { COPY_WIDTH, CODE_WIDTH } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Box,
  List,
  ListItem,
  Link,
  Heading1,
  Heading2,
  Text,
  Divider,
  Flex,
  COPY_WIDTH,
  CODE_WIDTH,
  React
};