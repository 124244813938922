// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-alert-mdx": () => import("./../../../src/pages/components/alert.mdx" /* webpackChunkName: "component---src-pages-components-alert-mdx" */),
  "component---src-pages-components-application-frame-mdx": () => import("./../../../src/pages/components/application-frame.mdx" /* webpackChunkName: "component---src-pages-components-application-frame-mdx" */),
  "component---src-pages-components-async-select-mdx": () => import("./../../../src/pages/components/async-select.mdx" /* webpackChunkName: "component---src-pages-components-async-select-mdx" */),
  "component---src-pages-components-box-mdx": () => import("./../../../src/pages/components/box.mdx" /* webpackChunkName: "component---src-pages-components-box-mdx" */),
  "component---src-pages-components-branded-nav-bar-mdx": () => import("./../../../src/pages/components/branded-nav-bar.mdx" /* webpackChunkName: "component---src-pages-components-branded-nav-bar-mdx" */),
  "component---src-pages-components-branding-mdx": () => import("./../../../src/pages/components/branding.mdx" /* webpackChunkName: "component---src-pages-components-branding-mdx" */),
  "component---src-pages-components-breadcrumbs-mdx": () => import("./../../../src/pages/components/breadcrumbs.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumbs-mdx" */),
  "component---src-pages-components-button-group-mdx": () => import("./../../../src/pages/components/button-group.mdx" /* webpackChunkName: "component---src-pages-components-button-group-mdx" */),
  "component---src-pages-components-buttons-mdx": () => import("./../../../src/pages/components/buttons.mdx" /* webpackChunkName: "component---src-pages-components-buttons-mdx" */),
  "component---src-pages-components-checkbox-group-mdx": () => import("./../../../src/pages/components/checkbox-group.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-group-mdx" */),
  "component---src-pages-components-checkbox-mdx": () => import("./../../../src/pages/components/checkbox.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-mdx" */),
  "component---src-pages-components-date-picker-mdx": () => import("./../../../src/pages/components/date-picker.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-mdx" */),
  "component---src-pages-components-date-range-mdx": () => import("./../../../src/pages/components/date-range.mdx" /* webpackChunkName: "component---src-pages-components-date-range-mdx" */),
  "component---src-pages-components-divider-mdx": () => import("./../../../src/pages/components/divider.mdx" /* webpackChunkName: "component---src-pages-components-divider-mdx" */),
  "component---src-pages-components-dropdown-menu-mdx": () => import("./../../../src/pages/components/dropdown-menu.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-menu-mdx" */),
  "component---src-pages-components-flex-mdx": () => import("./../../../src/pages/components/flex.mdx" /* webpackChunkName: "component---src-pages-components-flex-mdx" */),
  "component---src-pages-components-form-mdx": () => import("./../../../src/pages/components/form.mdx" /* webpackChunkName: "component---src-pages-components-form-mdx" */),
  "component---src-pages-components-form-section-mdx": () => import("./../../../src/pages/components/form-section.mdx" /* webpackChunkName: "component---src-pages-components-form-section-mdx" */),
  "component---src-pages-components-headings-mdx": () => import("./../../../src/pages/components/headings.mdx" /* webpackChunkName: "component---src-pages-components-headings-mdx" */),
  "component---src-pages-components-icon-mdx": () => import("./../../../src/pages/components/icon.mdx" /* webpackChunkName: "component---src-pages-components-icon-mdx" */),
  "component---src-pages-components-iconic-button-mdx": () => import("./../../../src/pages/components/iconic-button.mdx" /* webpackChunkName: "component---src-pages-components-iconic-button-mdx" */),
  "component---src-pages-components-input-mdx": () => import("./../../../src/pages/components/input.mdx" /* webpackChunkName: "component---src-pages-components-input-mdx" */),
  "component---src-pages-components-link-mdx": () => import("./../../../src/pages/components/link.mdx" /* webpackChunkName: "component---src-pages-components-link-mdx" */),
  "component---src-pages-components-list-mdx": () => import("./../../../src/pages/components/list.mdx" /* webpackChunkName: "component---src-pages-components-list-mdx" */),
  "component---src-pages-components-loading-animation-mdx": () => import("./../../../src/pages/components/loading-animation.mdx" /* webpackChunkName: "component---src-pages-components-loading-animation-mdx" */),
  "component---src-pages-components-modal-mdx": () => import("./../../../src/pages/components/modal.mdx" /* webpackChunkName: "component---src-pages-components-modal-mdx" */),
  "component---src-pages-components-overlay-mdx": () => import("./../../../src/pages/components/overlay.mdx" /* webpackChunkName: "component---src-pages-components-overlay-mdx" */),
  "component---src-pages-components-page-mdx": () => import("./../../../src/pages/components/page.mdx" /* webpackChunkName: "component---src-pages-components-page-mdx" */),
  "component---src-pages-components-pagination-mdx": () => import("./../../../src/pages/components/pagination.mdx" /* webpackChunkName: "component---src-pages-components-pagination-mdx" */),
  "component---src-pages-components-radio-group-mdx": () => import("./../../../src/pages/components/radio-group.mdx" /* webpackChunkName: "component---src-pages-components-radio-group-mdx" */),
  "component---src-pages-components-radio-mdx": () => import("./../../../src/pages/components/radio.mdx" /* webpackChunkName: "component---src-pages-components-radio-mdx" */),
  "component---src-pages-components-select-mdx": () => import("./../../../src/pages/components/select.mdx" /* webpackChunkName: "component---src-pages-components-select-mdx" */),
  "component---src-pages-components-sidebar-mdx": () => import("./../../../src/pages/components/sidebar.mdx" /* webpackChunkName: "component---src-pages-components-sidebar-mdx" */),
  "component---src-pages-components-status-indicator-mdx": () => import("./../../../src/pages/components/status-indicator.mdx" /* webpackChunkName: "component---src-pages-components-status-indicator-mdx" */),
  "component---src-pages-components-switcher-mdx": () => import("./../../../src/pages/components/switcher.mdx" /* webpackChunkName: "component---src-pages-components-switcher-mdx" */),
  "component---src-pages-components-table-mdx": () => import("./../../../src/pages/components/table.mdx" /* webpackChunkName: "component---src-pages-components-table-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../../../src/pages/components/tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-text-mdx": () => import("./../../../src/pages/components/text.mdx" /* webpackChunkName: "component---src-pages-components-text-mdx" */),
  "component---src-pages-components-textarea-mdx": () => import("./../../../src/pages/components/textarea.mdx" /* webpackChunkName: "component---src-pages-components-textarea-mdx" */),
  "component---src-pages-components-time-picker-mdx": () => import("./../../../src/pages/components/time-picker.mdx" /* webpackChunkName: "component---src-pages-components-time-picker-mdx" */),
  "component---src-pages-components-time-range-mdx": () => import("./../../../src/pages/components/time-range.mdx" /* webpackChunkName: "component---src-pages-components-time-range-mdx" */),
  "component---src-pages-components-toast-mdx": () => import("./../../../src/pages/components/toast.mdx" /* webpackChunkName: "component---src-pages-components-toast-mdx" */),
  "component---src-pages-components-toggle-mdx": () => import("./../../../src/pages/components/toggle.mdx" /* webpackChunkName: "component---src-pages-components-toggle-mdx" */),
  "component---src-pages-components-tooltip-mdx": () => import("./../../../src/pages/components/tooltip.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-mdx" */),
  "component---src-pages-components-truncated-text-mdx": () => import("./../../../src/pages/components/truncated-text.mdx" /* webpackChunkName: "component---src-pages-components-truncated-text-mdx" */),
  "component---src-pages-design-language-colour-js": () => import("./../../../src/pages/design-language/colour.js" /* webpackChunkName: "component---src-pages-design-language-colour-js" */),
  "component---src-pages-design-language-principles-mdx": () => import("./../../../src/pages/design-language/principles.mdx" /* webpackChunkName: "component---src-pages-design-language-principles-mdx" */),
  "component---src-pages-design-language-shadows-mdx": () => import("./../../../src/pages/design-language/shadows.mdx" /* webpackChunkName: "component---src-pages-design-language-shadows-mdx" */),
  "component---src-pages-design-language-spacing-mdx": () => import("./../../../src/pages/design-language/spacing.mdx" /* webpackChunkName: "component---src-pages-design-language-spacing-mdx" */),
  "component---src-pages-design-language-typography-mdx": () => import("./../../../src/pages/design-language/typography.mdx" /* webpackChunkName: "component---src-pages-design-language-typography-mdx" */),
  "component---src-pages-guides-designers-mdx": () => import("./../../../src/pages/guides/designers.mdx" /* webpackChunkName: "component---src-pages-guides-designers-mdx" */),
  "component---src-pages-guides-developer-workflow-mdx": () => import("./../../../src/pages/guides/developer-workflow.mdx" /* webpackChunkName: "component---src-pages-guides-developer-workflow-mdx" */),
  "component---src-pages-guides-guidelines-mdx": () => import("./../../../src/pages/guides/guidelines.mdx" /* webpackChunkName: "component---src-pages-guides-guidelines-mdx" */),
  "component---src-pages-guides-localization-mdx": () => import("./../../../src/pages/guides/localization.mdx" /* webpackChunkName: "component---src-pages-guides-localization-mdx" */),
  "component---src-pages-guides-ops-core-mdx": () => import("./../../../src/pages/guides/ops-core.mdx" /* webpackChunkName: "component---src-pages-guides-ops-core-mdx" */),
  "component---src-pages-guides-packages-mdx": () => import("./../../../src/pages/guides/packages.mdx" /* webpackChunkName: "component---src-pages-guides-packages-mdx" */),
  "component---src-pages-guides-style-props-mdx": () => import("./../../../src/pages/guides/style-props.mdx" /* webpackChunkName: "component---src-pages-guides-style-props-mdx" */),
  "component---src-pages-guides-testing-mdx": () => import("./../../../src/pages/guides/testing.mdx" /* webpackChunkName: "component---src-pages-guides-testing-mdx" */),
  "component---src-pages-guides-versioning-mdx": () => import("./../../../src/pages/guides/versioning.mdx" /* webpackChunkName: "component---src-pages-guides-versioning-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patterns-data-visualization-mdx": () => import("./../../../src/pages/patterns/data-visualization.mdx" /* webpackChunkName: "component---src-pages-patterns-data-visualization-mdx" */),
  "component---src-pages-patterns-loading-mdx": () => import("./../../../src/pages/patterns/loading.mdx" /* webpackChunkName: "component---src-pages-patterns-loading-mdx" */),
  "component---src-pages-resources-guidelines-mdx": () => import("./../../../src/pages/resources/guidelines.mdx" /* webpackChunkName: "component---src-pages-resources-guidelines-mdx" */),
  "component---src-pages-theme-mdx": () => import("./../../../src/pages/theme.mdx" /* webpackChunkName: "component---src-pages-theme-mdx" */)
}

