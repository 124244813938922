import { Table, IconicButton } from "@nulogy/components";
import { config } from "../../../../src/playgrounds/table";
import { tableRowProps, tableColumnProps, cellRendererArgs, headerFormatterArgs } from "../../../../src/playgrounds/table-props";
import { rows, columns } from "../../../../src/examples/table/data";
import { STORYBOOK_LINK } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Table,
  IconicButton,
  config,
  tableRowProps,
  tableColumnProps,
  cellRendererArgs,
  headerFormatterArgs,
  rows,
  columns,
  STORYBOOK_LINK,
  React
};