import { Modal, Button, PrimaryButton, QuietButton, ButtonGroup } from "@nulogy/components";
import { config } from "../../../../src/playgrounds/modal";
import * as React from 'react';
export default {
  Modal,
  Button,
  PrimaryButton,
  QuietButton,
  ButtonGroup,
  config,
  React
};