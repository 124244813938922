import { Icon, Link, Box, Text, Flex } from "@nulogy/components";
import { config } from "../../../../src/playgrounds/icon";
import iconList from "../../../../src/utils/iconList";
import { SLACK_LINK, CODE_WIDTH } from "../../../../src/components/CONSTANTS";
import * as React from 'react';
export default {
  Icon,
  Link,
  Box,
  Text,
  Flex,
  config,
  iconList,
  SLACK_LINK,
  CODE_WIDTH,
  React
};